import React from 'react'
import Slideshow from './Slideshow';
import Subscribe from './Subscribe';
import Introduce from './Introduce'
import Stories from './Stories';
import Prices from './Prices';



export default function Home() {
    return (
        <div>
       <Slideshow />
        <Subscribe />
        <Introduce />
        <Stories />

        <Prices />
        
    
        
            
        </div>
    )
}
