import React, { Component } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import './subs'

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './components/Home';
import Editions from './components/Editions';
import BaseFooter from './components/BaseFooter';
import PriceDetail from './components/PriceDetail';





class App extends Component {
  render() {

    return (
      <Router >
        <div className="App">

          <Navbar />
          <Switch>
            <Route exact path="/"><Home /></Route>
            <Route exact path="/editions">
              <Editions />
            </Route>

            <Route exact path="/subscription" >
              <PriceDetail />
            </Route>
          </Switch>
          <BaseFooter />




        </div></Router>
    );
  }
}

export default App;
