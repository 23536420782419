import axios from "axios";
import setting from "../src/configs/config";

window.onload = () => {
  if (
    !window.location.origin.includes("localhost") ||
    !window.location.origin.includes("localhost")
  )
    console.log("executing");
  axios.get(`${setting.url}/visitorCount`).then((res) => {
    let visitorCount = Number(res.data) + 1;
    axios
      .post(`${setting.url}/visitorCount/${visitorCount}`)
      .then((msg) => console.log(msg))
      .catch((err) => console.log(err));
  });
};
