import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import setting from "../configs/config";

export default class BaseFooter extends Component {
  state = {
    count: -1,
  };
  render() {
    return (
      <div className="base-footer " style={{ marginTop: "50px" }}>
        <div className="row bg-blue pb-4">
          <div className="col-md-3" />

          <div
            className="col-md-5 text-light mt-4 text-center"
            style={{
              borderRight: "2px solid grey",
              borderLeft: "2px solid grey",
            }}
          >
            <div className="info">
              <i className="fas fa-phone mx-1    p-2 rounded-circle bg-orange" />
              9350899583
            </div>
            <div className="info">
              <i className="far fa-envelope ml-4 mr-1 bg-danger  p-2 rounded-circle " />
              samkaleenabhivyakti@gmail.com
            </div>

            <hr />
            <h5>Samkaleen Abhivyakti &copy; 2019-2020</h5>
          </div>
          <div className="col-md-4 p-4">
            <li className="list-group-item p-2 bg-success">
              <i className="fas fa-user mr-2 bg-orange rounded-circle p-2 text-light" />
              <Link to="/subscription" className="text-light">
                पत्रिका से जुड़े
              </Link>
            </li>
            <li className="list-group-item bg-orange  p-2 mt-4 ">
              <i className="fas fa-book mr-2 text-light rounded-circle bg-success p-2" />
              <Link to="/editions" className="text-light">
                पत्रिका के अंक पढ़े{" "}
              </Link>
            </li>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    axios.get(`${setting.url}/visitorCount`).then((res) => {
      console.log(res);
      this.setState({ count: res.data + 1 });
    });
  }
}
