import React from 'react'

export default function Images() {
    return (

        <div className="carousel-inner">

            <div className="carousel-item active carousel-item-1">


                <div className="content-2">

                </div>

            </div>

            <div className="carousel-item  carousel-item-2">


                <div className="content-2">


                </div>

            </div>


        </div>

    )
}
