import React from "react";
import dataPdfs from "../pdf";
export default function Subscribe() {
  const pdfData = dataPdfs;
  return (
    <div className="showcase">
      <div className="bg-oprimary p-4 subscribeContainer" style={{}}>
        <div
          className="subscribe p-2 mb-4 mx-auto"
          style={{
            marginTop: "-80px",
            width: "90%",
            boxShadow: "0 10px 10px 10px #004d61",
          }}
        >
          <div className="row">
            <div className="col-lg-3 ">
              <div className="p-2 text-center" style={{ position: "relative" }}>
                <img
                  src={require("../images/magzine.jpg")}
                  height="270px"
                  alt="latest_magzine"
                />
                <img
                  src={require("./../images/new-magzine-banner.png")}
                  alt="new-banner"
                  style={{
                    width: "174px",
                    position: "absolute",
                    bottom: "0",
                    left: "50%",
                    transform: "translate(-46%, 0)",
                  }}
                />
              </div>
            </div>

            <div className="col-lg-9">
              <div className="p-2  text-light">
                <h3 className="bg-orange p-2 text-light d-inline-block">
                  <strong>समकालीन अभिव्यक्ति में आपका स्वागत है !</strong>
                </h3>
                <hr />
                <p
                  style={{
                    fontSize: "15px",
                    lineHeight: "1.8em",
                    wordSpacing: "0.4em",
                  }}
                >
                  'समकालीन अभिव्यक्ति' एक साहित्यिक आंदोलन है | साहित्यिक चेतना
                  से जुड़े हर वर्ग एवं विधा को लेकर एक सकारात्मक परिवेश बनाना इस
                  पत्रिका का लक्ष्य है। हिंदी भाषा में आज साहित्यिक पत्रिकाओं का
                  अभाव तो नहीं है, किंतु विमर्श एवं वाद के नाम पर परोसी जाने
                  वाली सामग्री साहित्य के वास्तविक उदेश्य से कहीं न कहीं भटक
                  जरूर चुकी है। आवश्यकता है कि उसमें सांस्कृतिक चेतना का भी पुट
                  हो। 'समकालीन अभिव्यक्ति' इस कमी की भरपाई करने का प्रयास कर रही
                  है। कल, आज और कल के लेखनकर्मियों को साथ लेकर चलती हुई 'समकालीन
                  अभिव्यक्ति' ने 18 वर्षोँ का लंबा सफर तय किया है। इस अवधि में
                  इसे विशुद्ध पाठकों का भरपूर समर्थन भी मिला है। यह आंदोलन सफल
                  हो, इसके लिए आपसे जुड़ाव की अपेक्षा है |
                </p>

                <a
                  className="btn btn-success p-2 btn-sm"
                  href={require(`../pdfs/${pdfData[0]}`)}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  नया अंक पढ़े
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
