import React from 'react'

export default function PriceDetail() {
    window.scrollTo(0, 0)
    return (
        <div className="price p-4 mt-4 rounded">


            <img src={require("../images/magzineSub.jpg")} alt="magzine_subscription" width="50%" style={{ margin: "auto", display: "block" }} />


        </div>
    )
}
