import React from 'react'

export default function Introduce() {
    return (
        <div className=' container my-4 introduce'>

            <div className="row">

                <div className="col-md-6 mb-4">
                    <div className="card p-3 bg-success">
                        <div className="row">

                            <div className="col-sm-4 ">

                              <div className="text-center imgC">  <img src={require("../images/um.jpg")} alt="" className=' img-fluid ' /></div>
                            </div>

                            <div className="col-sm-8 ">
                                <div className="text-light">
                                    <h3>उपेन्द्र कुमार मिश्र
                                        
                                    </h3><h5 className='mt-1 text-center'>(संपादक)</h5>
                                    <hr />               <p>
                                        1967 में गोरखपुर में जन्मे उपेन्द्र कुमार मिश्र हिंदी साहित्य में एम.ए. एवं बी.एड्. हैं। छात्र जीवन से ही साहित्य से गहरा जुड़ाव 'समकालीन अभिव्यक्ति' के प्रकाशन एवं संपादन तक खीच लाया |

                                    </p>
                                </div>

                              

                            </div>
                        </div>

                    </div>


                </div>

                <div className="col-md-6 ">
                    <div className="card p-3 bg-light">
                        <div className="row">

                            <div className="col-sm-4 text-center">

                                <img src={require("../images/hr.jpg")} alt="" className=' img-fluid' width='150px' />
                            </div>

                            <div className="col-sm-8">

                                <div className="text-secondary">
                                    <h3>हरिशंकर राढ़ी
                                  

                                    </h3>  <h5 className='mt-1 text-center'>(सहसंपादक)</h5>
                                    <hr />               <p>
                                        1964 में आजमगढ़ में जन्मे हरिशंकर राढ़ी अंगरेजी साहित्य एवं संस्कृत में एम.ए. के साथ बी.एड् हैं। राढ़ी जी की रचनाएँ देश की अनेक साहित्यिक एवं व्यावसायिक पत्र-पत्रिकाओं में प्रकाशित होती  हैं |

                                    </p>

                                   
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>


        </div>
    )
}
