import React from 'react'
import {Link} from 'react-router-dom'

export default function Navbar() {
  
    return (
        <div >
            


            <nav className="navbar navbar-expand-md navbar-dark  text-light bg-success " >

                <Link to="/" className="navbar-brand m-auto" style={{  fontSize: '33px' }}>
                    <strong className='text-light  rounded-circle p-2' style={{backgroundColor:"rgb(250, 120, 27,0.8)"}}>स</strong>मकालीन  <strong className='text-light rounded-circle p-2' style={{backgroundColor:"rgb(240, 65, 53,0.8)"}}>अ</strong>भिव्यक्ति
                </Link>



                
            </nav>

            <nav className="navbar navbar-light  text-dark circle  bg-blue py-2 navbar-expand nav-sec" >
            
{window.location.pathname.split( '/' )[1]!=="" ?    <li className="nav-item mx-2 bg-primary rounded-circle  p-2  backButton" data-toggle="tooltip" data-placement="top" title="Back To Home Page" style={{listStyleType:"none"}}>
                    <Link to="/">
 <img src={require("../images/return.png")} alt="return_button" width="32px"/>
</Link></li> : null}
                
             
               <ul className="navbar-nav m-auto">
                     
                    
                    <li className="nav-item mx-2" data-toggle="tooltip" data-placement="top" title="पत्रिका के अंक पढ़े">
                        <Link to="/editions" className="nav-link bg-primary p-2 rounded-circle">
                            <img src={require("../images/book.svg")} alt="" width="32px" className=""  />
                        </Link>
                    </li>
                    <li className="nav-item" data-toggle="tooltip" data-placement="top" title="पत्रिका से जुड़े">
                        <Link to="/subscription" className="nav-link bg-primary p-2 rounded-circle">
                            <img src={require("../images/collaboration.png")} alt="" width="32px"  />
                        </Link>
                    </li>
                </ul>

            </nav>


        </div >
    )
}
