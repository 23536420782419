import React, { Component } from 'react'
import {Link} from 'react-router-dom'


export default class Stories extends Component {
    render() {
        return (
            <div className='stories '>
                <div className="content text-light row ">

                    <div className=' col-lg-9'>
                        <div style={{ paddingLeft: '50px' }}>

                            <div className='bg-blue d-inline-block mb-4 p-3 rounded'> समकालीन अभिव्यक्ति </div>
                            <div>
                                <div className='bg-success p-3 d-inline-block mb-2'>की प्रसिद्ध  </div></div>
                            <div className='bg-orange p-3 d-inline-block mt-2 '>कविताएँ  और कहानियाँ</div></div>
                    </div>
                    <div className="col-lg-3 ">
                        <div className="container " style={{}}>
                            <div className="text-center bookImg">
                                <img src={require("../images/book.svg")}  alt="" width="128px" className="img-fluid"/>
                            </div>
                            <div className="text-center btn-block">
                                <hr/>
                                <Link to="/editions" className=" btn btn-outline-danger text-light" >

                                पत्रिका के अंक पढ़े 
                           
                            </Link></div>
                            



                        </div></div></div>


            </div>
        )
    }
}
