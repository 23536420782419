import React from 'react'
import {Link} from 'react-router-dom'

export default function Prices() {
    return (
        <div className='card my-4 p-4' style={{ backgroundImage: "url('https://image.freepik.com/free-vector/white-3d-modern-background-design_53876-88246.jpg')", backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <div className=" container text-center prices">

                <h1 className='text-secondary'>
                    समकालीन   अभिव्यक्ति  का हिस्सा बने
                </h1>

                <img src={require("../images/collaboration.png")} alt="" />

                <div> <Link to="/subscription" className="btn  bg-orange text-light my-2 ">और जाने </Link></div>
            </div>

        </div>
    )
}
