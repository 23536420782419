import React, { Component } from "react";
import axios from "axios";
import setting from "../configs/config";
import dataPdfs from "../pdf";

export default class Editions extends Component {
  state = {
    imageSrc: [],
  };

  render() {
    Array(dataPdfs).reverse();
    const pdfsData = dataPdfs;
    console.log(dataPdfs);

    const imagesSrc = this.state.imageSrc;

    console.log(imagesSrc);
    window.scrollTo(0, 0);

    return imagesSrc.length === 0 ? (
      <h1>Loading...</h1>
    ) : (
      <div className=" p-0 container my-4 editions  ">
        <h2 className=" p-3 text-light  text-light bg-secondary ">
          पत्रिका के अंक{" "}
        </h2>

        <div className=" show p-4">
          <div className="row ">
            {imagesSrc.map((imgSrc, index) => {
              return (
                <div className="col-md-3 mb-4 col-6" key={index}>
                  <div className="magzineImages ">
                    <div className="content   text-center p-3">
                      <strong>
                        <p className="text-ldark p-2 bg-light ">
                          अंक :
                          {Number(imgSrc.slice(0, 2))
                            ? ` ${imgSrc
                                .slice(3, 6)
                                .replace(
                                  imgSrc.slice(3, 6)[0],
                                  imgSrc.slice(3, 6)[0].toUpperCase()
                                )}-${imgSrc
                                .slice(8, 11)
                                .replace(
                                  imgSrc.slice(8, 11)[0],
                                  imgSrc.slice(8, 11)[0].toUpperCase()
                                )} (20${imgSrc.slice(11, 13)})`
                            : ` ${imgSrc
                                .slice(2, 5)
                                .replace(
                                  imgSrc.slice(2, 5)[0],
                                  imgSrc.slice(2, 5)[0].toUpperCase()
                                )}-${imgSrc
                                .slice(7, 10)
                                .replace(
                                  imgSrc.slice(7, 10)[0],
                                  imgSrc.slice(7, 10)[0].toUpperCase()
                                )} (20${imgSrc.slice(10, 12)})`}
                        </p>
                      </strong>

                      <div className="paneltext-center">
                        {" "}
                        <hr />
                        <a
                          className="mr-4"
                          href={require(`../pdfs/${pdfsData[index]}`)}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <i className="fas fa-download text-light bg-primary p-2 rounded-circle" />
                        </a>
                      </div>
                    </div>
                    <img
                      src={`${setting.url}/thumbnailimage/${index}`}
                      alt=""
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const url = setting.url;
    axios.get(`${url}/imageNames`).then((imagesPath) => {
      this.setState({
        imageSrc: imagesPath.data,
      });
    });
  }
}
